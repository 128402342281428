import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import correctSign from "../../Images/correct-sign.svg";
import review from "../../Images/review.svg";
import ifApproved from "../../Images/Eligibility.svg";
import reapply from "../../Images/reapply.svg";
import { Button } from "primereact/button";
import ReadMore from "../ReadMore/ReadMore";
import { useLocation } from "react-router-dom";
import WhatsNext from "./WhatsNext";
import ContactUs from "./ContactUs";
import DownloadForm from "../HowToApply/DownloadForm";

function Eligible({ pageData, lang }) {
  const location = useLocation();

  const [selectedMedicine, setSelectedMedicine] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [showBodyText, setShowBodyText] = useState(false);

  useEffect(() => {
    if (location && location.state) {
      setSelectedMedicine(location.state.prescribedMedicine);
      let medicine = location.state.prescribedMedicine;
      const products = [
        "ABRAXANE&REG;",
        "EMPLICITI&REG;",
        "ISTODAX&REG;",
        "NULOJIX&REG;",
        "OPDIVO&REG;",
        "OPDIVO QVANTIG&TRADE;",
        "OPDUALAG&TRADE;",
        "ORENCIA&REG;",
        "REBLOZYL&REG;",
        "VIDAZA&REG;",
        "YERVOY&REG;",
     ];
      if(products.indexOf(medicine.tradeName.toUpperCase()) > -1)
      {
        setShowBodyText(true);
      }
    }
  });

  const backToTop = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
  }

  const openDocument = () => {
    setShowDialog(true);
  };

  const updateShowDialog = (isShow) => {
    setShowDialog(isShow);
  };

  return (
    <main id="main">
      <div className="eligible-main-div">
        <div className="eligible-content container">
          <div className="row g-0">
            <div className="col-sm-12 mx-auto">
              <h1>{pageData.title}</h1>
            </div></div></div>
        <div className="container-fluid">
          <div className="row g-0">
            <div className="eligible-dialogue col-xl-12 col-lg-12 col-md-12 col-sm-12 col-sm-12 mx-auto d-flex justify-content-center align-items-center">
              <span className="eligible-correct-icon"><img className="correct-icon" src={correctSign} alt="correct-sign" /></span>
              <span className="eligible-dialogue-text">
                {location && location.state && location.state.extraInfo ?
                  <h3 dangerouslySetInnerHTML={{ __html: pageData.dialogue_extraInfo }}></h3>
                  :
                  <h3 dangerouslySetInnerHTML={{ __html: pageData.dialogue }}></h3>
                }
              </span>
            </div>
          </div>
        </div>
        <div className="eligible-content-body container">
          {showBodyText && <div className="row g-0">
            <div className="col-md-8 col-sm-12 mx-auto">
              <p className="body-large">{pageData.body1.title}</p>
              <div className="custom-bullet-main-div">
                <ul className="custom-list">
                  <li dangerouslySetInnerHTML={{ __html: pageData.body1.bullet1 }}></li>
                </ul>
              </div>
            </div>
          </div>
          }
          <div className="row g-0">
            <div className="col-md-8 col-sm-12 mx-auto">
              <div><h4><span className="circle-outline">{pageData.body.index_1.id}</span>&nbsp;{pageData.body.index_1.title}</h4></div>
              <div className="custom-list-div">
                <ul className="custom-list">
                  {pageData.body.index_1.list.map(item => (
                    <li dangerouslySetInnerHTML={{ __html: item }}></li>
                  ))}
                </ul>
              </div>
              <div className="button-contact">
                <span><Button
                  label={pageData.body.index_1.btnLabel}
                  className="p-button-outlined p-button-help"
                  onClick={() => openDocument()}
                /></span>
                <span className="contact-fax"><p dangerouslySetInnerHTML={{ __html: pageData.body.index_1.contactMsg }}></p></span>
              </div>
              <hr aria-hidden="true" />
            </div>
          </div>
          <div className="row g-0">
            <div className="col-md-8 col-sm-12 mx-auto">
              <p><h4><span className="circle-outline">{pageData.body.index_2.id}</span>&nbsp;{pageData.body.index_2.title}</h4></p>
              <p className="doctor-signature">{pageData.body.index_2.text}</p>
              <hr aria-hidden="true" />
            </div>
          </div>
          <div className="row g-0">
            <div className="col-md-8 col-sm-12 mx-auto">
              <p><h4><span className="circle-outline">{pageData.body.index_3.id}</span>&nbsp;{pageData.body.index_3.title}</h4></p>
              <div className="send-application-list">
                <ul className="custom-list">
                  {pageData.body.index_3.list.map(item => (
                    <li dangerouslySetInnerHTML={{ __html: item }}></li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="row g-0">
            <ContactUs contactData={pageData.moreInfo.contactContent} />
          </div>
          <hr aria-hidden="true" />
        </div>
        <WhatsNext content={pageData.whatsNext} />
        <div className="container-fluid">
          <div className="eligible-back-to-top row g-0">
            <div className="col-md-8 col-sm-12 mx-auto">
              <Link tabIndex={0} style={{ "marginTop": "1em", "color": "black", "border-bottom": "2px solid #EB7100", "textDecoration": "none" }} onClick={(e) => backToTop(e)}><span class="bold-span">{pageData.backBtnLabel}</span> </Link>
            </div>
          </div>
        </div>
        <ReadMore content={pageData.readMore} />
      </div>
      <DownloadForm lang={lang} dialogData={pageData.downloadForm} selectedMedicine={{ medicineDetails: selectedMedicine }} showDialog={showDialog} updateShowDialog={updateShowDialog} />
    </main >
  );
}

export default Eligible;
